import React, { useState, useEffect } from 'react';
import { Container, Button, Navbar, Nav, Image, Modal } from 'react-bootstrap';
import axios from 'axios';
import BlogControl from './BlogControl';
import ReportControl from './ReportControl';
import AdminSupport from './AdminSupport';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt, faBlog, faLifeRing, faUserCircle, faBell, faCog } from '@fortawesome/free-solid-svg-icons';
import logo from "../../assets/Navbarlogo.jpg";
import 'bootstrap/dist/css/bootstrap.min.css';

const AdminDashboard = () => {
  const [reports, setReports] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedReport, setSelectedReport] = useState(null);
  const [activeComponent, setActiveComponent] = useState('reports');

  useEffect(() => {
    const fetchReports = async () => {
      try {
        const response = await axios.get('http://hiredsafe.com/PHP/get_report_by_id.php');
        setReports(response.data);
      } catch (error) {
        console.error('Error fetching reports:', error);
      }
    };

    fetchReports();
  }, []);

  const handleVerify = async (id) => {
    try {
      const response = await axios.post(`http://hiredsafe.com/PHP/verify_report.php`, { id: id });
      setReports(reports.map(report => report.id === id ? { ...report, status: 'Verified' } : report));
    } catch (error) {
      console.error('Error verifying report:', error);
    }
  };
  
  const handlePublish = async (id) => {
    try {
      const response = await axios.post(`http://hiredsafe.com/PHP/publish_report.php`, { id: id });
      if (response.status === 200) {
        setReports(reports.map(report => report.id === id ? { ...report, status: 'Published' } : report));
      }
    } catch (error) {
      console.error('Error publishing report:', error);
    }
  };
  const handleDelete = async (id) => {
    try {
      const response = await axios.post(
        `http://hiredsafe.com/PHP/delete_report.php`,  // Use POST if PHP is handling it that way
        { id: id },  // Send the ID in the request body
        { headers: { 'Content-Type': 'application/json' } }  // Ensure the request is recognized as JSON
      );
      if (response.status === 200) {
        setReports(reports.filter(report => report.id !== id));  // Update state to remove the deleted report
      }
    } catch (error) {
      console.error('Error deleting report:', error.response ? error.response.data : error.message);
    }
  };
  
  

  const handleView = (report) => {
    setSelectedReport(report);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedReport(null);
  };

  return (
    <div style={styles.dashboard}>
      <div style={styles.sidebar}>
        <Image src={logo} width={'200px'} />
        <div style={styles.logo}>Admin</div>
        <Button 
          variant={activeComponent === 'reports' ? "primary" : "outline-light"} 
          className="mb-3 w-100" 
          onClick={() => setActiveComponent('reports')}
          style={styles.sidebarButton}
        >
          <FontAwesomeIcon icon={faFileAlt} className="mr-2" /> Report Control
        </Button>
        <Button 
          variant={activeComponent === 'blogs' ? "primary" : "outline-light"} 
          className="mb-3 w-100" 
          onClick={() => setActiveComponent('blogs')}
          style={styles.sidebarButton}
        >
          <FontAwesomeIcon icon={faBlog} className="mr-2" /> Blog Control
        </Button>
        <Button 
          variant={activeComponent === 'support' ? "primary" : "outline-light"} 
          className="w-100" 
          onClick={() => setActiveComponent('support')}
          style={styles.sidebarButton}
        >
          <FontAwesomeIcon icon={faLifeRing} className="mr-2" /> Support
        </Button>
      </div>
      <div style={styles.content}>
        <Navbar bg="light" expand="lg" className="mb-4">
          <Nav className="ml-auto">
            <Nav.Link href="/adminblog">
              <Button variant="success" style={styles.writeBlogButton}>
                Write Blog
              </Button>
            </Nav.Link>
            <Nav.Link href="#profile">
              <FontAwesomeIcon icon={faUserCircle} size="lg" />
            </Nav.Link>
            <Nav.Link href="#notifications">
              <FontAwesomeIcon icon={faBell} size="lg" />
            </Nav.Link>
            <Nav.Link href="#settings">
              <FontAwesomeIcon icon={faCog} size="lg" />
            </Nav.Link>
          </Nav>
        </Navbar>
        <Container fluid>
          {activeComponent === 'reports' && (
            <ReportControl 
              reports={reports} 
              handleVerify={handleVerify} 
              handlePublish={handlePublish} 
              handleDelete={handleDelete} 
              handleView={handleView} 
              showModal={showModal} 
              selectedReport={selectedReport} 
              handleCloseModal={handleCloseModal}
            />
          )}
          {activeComponent === 'blogs' && <BlogControl />}
          {activeComponent === 'support' && <AdminSupport />}
        </Container>
      </div>
    </div>
  );
};

export default AdminDashboard;

const styles = {
  dashboard: {
    display: 'flex',
    height: '100vh',
    backgroundColor: '#f1f2f6',
  },
  sidebar: {
    width: '250px',
    height: '100vh',
    backgroundColor: '#2d3436',
    color: '#dfe6e9',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '20px',
    boxShadow: '2px 0 5px rgba(0, 0, 0, 0.15)',
    position: 'sticky',
    top: '0',
  },
  logo: {
    fontSize: '24px',
    fontWeight: 'bold',
    color: '#ffffff',
    marginBottom: '30px',
  },
  sidebarButton: {
    fontSize: '16px',
    fontWeight: 'bold',
    color: '#ffffff',
  },
  content: {
    flexGrow: 1,
    padding: '20px',
    backgroundColor: '#ffffff',
    borderLeft: '1px solid #dfe6e9',
    overflowY: 'auto',
  },
  writeBlogButton: {
    fontSize: '14px',
    padding: '8px 16px',
    borderRadius: '5px',
  },
};
