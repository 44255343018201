import React, { useEffect, useState, useMemo } from 'react';
import { Table, Button, Modal, Row, Col } from 'react-bootstrap';
import axios from 'axios';

const ReportControl = ({ handleVerify, handlePublish, handleView, handleDelete }) => {
  const [reports, setReports] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedReport, setSelectedReport] = useState(null);

  // Fetch all reports from the server
  const fetchReports = async () => {
    try {
      const response = await axios.get('http://hiredsafe.com/PHP/get_all_reports.php');
      console.log('Fetched reports:', response.data); // Log the fetched data to debug
      setReports(response.data);
    } catch (error) {
      console.error('Error fetching reports:', error);
    }
  };

  useEffect(() => {
    fetchReports();
  }, []);

  const counts = useMemo(() => {
    const pending = reports.filter(report => report.status === 'Pending').length;
    const verified = reports.filter(report => report.status === 'Verified').length;
    const published = reports.filter(report => report.status === 'Published').length;
    return { pending, verified, published };
  }, [reports]);

  const handleCloseModal = () => setShowModal(false);

  return (
    <div>
      <h2>Report Control</h2>

      <Row className="mb-4">
        <Col>
          <div style={summaryBoxStyle}>
            <h4 style={summaryHeadingStyle}>Pending Reports</h4>
            <p style={summaryCountStyle}>{counts.pending}</p>
          </div>
        </Col>
        <Col>
          <div style={summaryBoxStyle}>
            <h4 style={summaryHeadingStyle}>Verified Reports</h4>
            <p style={summaryCountStyle}>{counts.verified}</p>
          </div>
        </Col>
        <Col>
          <div style={summaryBoxStyle}>
            <h4 style={summaryHeadingStyle}>Published Reports</h4>
            <p style={summaryCountStyle}>{counts.published}</p>
          </div>
        </Col>
      </Row>

      <Table striped bordered hover responsive>
        <thead>
          <tr style={{ backgroundColor: '#287094', color: '#ffffff' }}>
            <th>ID</th>
            <th>Company</th>
            <th>Date</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {reports.map(report => (
            <tr key={report.id}>
              <td>{report.id}</td>
              <td>{report.COMPANY_NAME || 'No Company Name'}</td>
              <td>{report.DATEOFSCAM ? new Date(report.DATEOFSCAM).toLocaleDateString() : 'No Date'}</td>
              <td>
                <span className="status" style={statusStyle(report.status)}>
                  {report.status || 'No Status'}
                </span>
              </td>
              <td>
                <div className="d-flex gap-2">
                  {report.status !== 'Verified' && (
                    <Button variant="success" onClick={() => handleVerify(report.id)}>
                      Verify
                    </Button>
                  )}
                  {report.status === 'Verified' && (
                    <Button variant="primary" onClick={() => handlePublish(report.id)}>
                      Publish
                    </Button>
                  )}
                  <Button variant="info" onClick={() => handleView(report)}>
                    View
                  </Button>
                  <Button variant="danger" onClick={() => handleDelete(report.id)}>
                    Delete
                  </Button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Report Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>  
          {selectedReport && (
            <div>
              <p><strong>ID:</strong> {selectedReport.id}</p>
              <p><strong>Company Name:</strong> {selectedReport.COMPANY_NAME}</p>
              <p><strong>Company Address:</strong> {selectedReport.COMPANY_ADRESS}</p>
              <p><strong>Industry:</strong> {selectedReport.industry}</p>
              <p><strong>Date of Scam:</strong> {selectedReport.DATEOFSCAM ? new Date(selectedReport.DATEOFSCAM).toLocaleDateString() : 'No Date'}</p>
              <p><strong>Description:</strong> {selectedReport.DESCRIPTION}</p>
              <p><strong>Reporter Name:</strong> {selectedReport.REPORTER_NAME}</p>
              <p><strong>Reporter Email:</strong> {selectedReport.REPORTER_EMIAL}</p>
              {selectedReport.evidence && (
                <div>
                  <strong>Evidence:</strong>
                  <img src={`http://hiredsafe.com/PHP/uploads/${selectedReport.EVIDENCE}`} alt="evidence" style={{ maxWidth: '100%', height: 'auto' }} />
                </div>
              )}
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>Close</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};


// Styles for the status badge
const statusStyle = (status) => ({
  padding: '5px 10px',
  borderRadius: '4px',
  fontSize: '14px',
  backgroundColor: status === 'Pending' ? '#ffcc00' : status === 'Verified' ? '#2196f3' : '#4caf50',
  color: '#fff'
});

// Styles for the summary boxes
const summaryBoxStyle = {
  backgroundColor: '#ffffff',
  padding: '20px',
  borderRadius: '8px',
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  textAlign: 'center',
};

const summaryHeadingStyle = {
  fontSize: '18px',
  color: '#287094',
  marginBottom: '10px'
};

const summaryCountStyle = {
  fontSize: '24px',
  fontWeight: 'bold',
  color: '#333'
};

export default ReportControl;
