import React, { useState } from 'react';
import { Navbar, Nav, Form, FormControl, Button, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import logo from '../../assets/hiredsafelogo.png';

const Navbar1 = ({ onSearch, setLogin }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [searchType, setSearchType] = useState('reports'); // Default to searching reports

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleTypeChange = (e) => {
    setSearchType(e.target.value);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    onSearch(searchQuery, searchType); // Pass the search type to the onSearch function
  };

  const handleLogout = () => {
    setLogin(false); // Reset login status when admin logs out
  };

  return (
    <Navbar bg="light" expand="lg" className="border-bottom shadow-sm py-2 sticky-top">
      <Container>
        <Navbar.Brand href="/" className="d-flex align-items-center">
          <img
            src={logo}
            alt="Logo"
            style={{ height: '50px' }} // Adjust the height as needed
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Form inline className="mr-auto flex-grow-1 d-flex" onSubmit={handleSearchSubmit}>
            <FormControl
              type="text"
              placeholder="Search Companies or Scams"
              className="mr-sm-2 w-50"
              value={searchQuery}
              onChange={handleSearchChange}
            />
            {/* <Form.Select className="ml-2" value={searchType} onChange={handleTypeChange}>
              <option value="reports">Reports</option>
              <option value="blogs">Blogs</option>
            </Form.Select> */}
            <Button type="submit" variant="outline-success" className="ml-5">Search</Button>
          </Form>
          <Nav className="">
            {setLogin ? (
              <>
                <Button href="/writeblog" className="btn btn-primary mr-3">Write Blog</Button>
                <Button onClick={handleLogout} className="btn btn-secondary">Logout</Button>
              </>
            ) : (
              <>
                <Nav.Link href="/" className="mr-3">Home</Nav.Link>
                <Nav.Link href="/reports" className="mr-3">Reports</Nav.Link>
                <Nav.Link href="/blog-details" className="mr-5">Blog</Nav.Link>
                <Nav.Link href="/about" className="mr-5">About</Nav.Link>
                <Nav.Link href="/contact" className="mr-3">Contact</Nav.Link>
                <Button href="/reportscam" className="btn btn-primary">Report Scam</Button>
              </>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Navbar1;
