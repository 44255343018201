import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Home from './component/pages/Home';
import ReportScam from './component/pages/ReportScam';
import Contactus from './component/pages/Contactus';
import Navbar1 from './component/pages/common/Navbar';
import Footer from './component/pages/common/Footor';
import BlogPage from './component/pages/BlogPage';
import BlogDetails from './component/pages/Blog-details';
import ReportDetails from './component/pages/ReportDetail1';
import AdminDashboard from './component/pages/Adminpages/AdminDashboard';
import BlogDetailsMore from './component/pages/Blog-detailsMore';
import ReportsPage from './component/pages/ReportsPage';
import SearchResults from './component/pages/SearchResults';
import AdminLogin from './component/pages/Adminpages/AdminLogin';
import AdminBlogPage from './component/pages/Adminpages/AdminBlogPage';
import ProtectedRoute from './component/pages/common/ProtectedRoute';
import About from './component/pages/About';


function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

function AppContent() {
  const location = useLocation();
  const [searchResults, setSearchResults] = useState([]);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const handleSearch = async (query, type) => {
    try {
      const response = await fetch(`http://hiredsafe.com/PHP/search.php?query=${encodeURIComponent(query)}&type=${encodeURIComponent(type)}`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setSearchResults(data);
    } catch (error) {
      console.error('Error fetching search results:', error);
    }
  };

  return (
    <div>
      <Navbar1 onSearch={handleSearch} />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/reportscam" element={<ReportScam />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contactus />} />
        <Route path="/createblog" element={<BlogPage />} />
        <Route path="/blog-details" element={<BlogDetails />} />
        <Route path="/reportdetails/:id" element={<ReportDetails />} />
        <Route path="/blog-details/:id" element={<BlogDetailsMore />} />
        <Route path="/reports" element={<ReportsPage />} />
        <Route path="/search" element={<SearchResults results={searchResults} />} />
        <Route path="/adminlogin" element={<AdminLogin onLogin={() => setIsAuthenticated(true)} />} />

        {/* Protected Routes */}
        <Route
          path="/admindashboard"
          element={
            <ProtectedRoute isAuthenticated={isAuthenticated}>
              <AdminDashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/adminblog"
          element={
            <ProtectedRoute isAuthenticated={isAuthenticated}>
              <AdminBlogPage />
            </ProtectedRoute>
          }
        />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
