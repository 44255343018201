import React from 'react';
import { Card, Container, Row, Col } from 'react-bootstrap';

const SearchResults = ({ results }) => {
  return (
    <Container className="my-4">
      <h3>Search Results</h3>
      <Row>
        {results.map((result) => (
          <Col md={4} className="mb-4" key={result.id}>
            <Card>
              <Card.Body>
                <Card.Title>{result.name || result.title}</Card.Title>
                <Card.Text>{result.description || result.content}</Card.Text>
                <a href={result.link} className="btn btn-primary">View Details</a>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default SearchResults;
