import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useEffect, useState } from 'react';
import { Card, Col, Container, Row, Button,Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Blog_Hero from "../assets/bloghero.jpg";
import HashLoader from "react-spinners/HashLoader";

const BlogDetails = () => {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true); 
  const [showAll, setShowAll] = useState(false); 

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await axios.get('http://hiredsafe.com/PHP/get_blogs.php');
        console.log('Fetched Blogs:', response.data); // Log the response data
        setBlogs(response.data);
      } catch (error) {
        console.error('There was an error fetching the blogs:', error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchBlogs();
  }, []);
  

  const displayedBlogs = showAll ? blogs : blogs.slice(0, 6); 

  const blogRows = [];
  for (let i = 0; i < displayedBlogs.length; i += 3) {
    blogRows.push(displayedBlogs.slice(i, i + 3));
  }

  return (
    <div>
      <main>
        <section className="blog-intro py-5" style={{ backgroundColor: '#e0f7fa' }}>
          <Container>
            <Row className="align-items-center">
              <Col md={6}>
                <div className="blog-intro-content">
                  <h2 style={{ fontSize: '36px', color: '#287094', marginBottom: '20px' }}>
                    Empower Your Job Search with Knowledge and Insights
                  </h2>
                  <p style={{ marginBottom: '20px', color: '#333' }}>
                    Welcome to the HiredSafe Blog, your trusted resource for navigating the job market with confidence and security. Here, we provide expert tips, in-depth articles, and real-life stories to help you identify and avoid job scams, understand industry trends, and make informed career decisions. Whether you're a fresh graduate stepping into the workforce or an experienced professional seeking new opportunities, our blog is dedicated to empowering you with the knowledge and tools you need to stay ahead in the ever-evolving job market. Stay safe, stay informed, and embark on your search with HiredSafe by your side.
                  </p>
                  <a href="/createblog" className="button" style={buttonStyle}>Write Blog</a>
                </div>
              </Col>
              <Col md={6} className="text-center">
                <div className="blog-intro-image">
                  <img src={Blog_Hero} alt="Blog Introduction Image" style={{ maxWidth: '100%', height: 'auto' }} />
                </div>
              </Col>
            </Row>
          </Container>
        </section>

     {/* Blog Section */}
<section className="blog py-5" style={{ backgroundColor: '#287094' }}>
  <Container>
    <h3 className="mb-4 text-white">From the Blog</h3>
    <Row className="blog-posts">
      {displayedBlogs.map((blog) => (
        <Col md={4} className="mb-4" key={blog.id}> {/* Adjusted column width for 3 per row */}
          <Card className="h-100 shadow-sm">
            <div className="post-image">
              <Image 
                src={blog.upload || 'placeholder-image-url.jpg'}  // Fallback if image is missing
                alt="Blog Image" 
                fluid 
                style={{ width: '100%', height: '200px', objectFit: 'cover' }}
              />
            </div>
            <Card.Body>
              <Card.Text className="author">{blog.author_name || 'Unknown Author'}</Card.Text>
              <Card.Text className="date">
                Published on {blog.created_at ? new Date(blog.created_at).toLocaleDateString() : 'N/A'}
              </Card.Text>
              <Card.Title>{blog.blog_title || 'Untitled Blog'}</Card.Title>
              <Card.Text>
                {blog.blog_description && blog.blog_description.length > 150 
                  ? `${blog.blog_description.substring(0, 150)}...` 
                  : 'No description available'}
              </Card.Text>
              <Link to={`/blog-details/${blog.id}`} className="read-more">Read More</Link>
              </Card.Body>
          </Card>
        </Col>
      ))}
    </Row>
  </Container>
</section>

      </main>
    </div>
  );
};

export default BlogDetails;
// Inline styles
const buttonStyle = {
  display: 'inline-block',
  padding: '10px 20px',
  backgroundColor: '#287094',
  color: '#fff',
  textDecoration: 'none',
  borderRadius: '5px',
  transition: 'background-color 0.3s',
  textAlign: 'center'
};

const postStyle = {
  backgroundColor: '#ffffff',
  padding: '0',
  borderRadius: '8px',
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between'
};

const postImageStyle = {
  width: '100%',
  height: '160px',
  overflow: 'hidden',
  borderTopLeftRadius: '8px',
  borderTopRightRadius: '8px'
};

const authorStyle = {
  fontSize: '14px',
  color: '#555',
  fontWeight: 'bold',
  marginBottom: '5px'
};

const postTitleStyle = {
  fontSize: '20px',
  marginBottom: '10px',
  color: '#287094',
  fontWeight: 'bold'
};

const postTextStyle = {
  marginBottom: '10px',
  color: '#333',
  fontSize: '14px'
};

const readMoreStyle = {
  color: '#287094',
  textDecoration: 'none',
  fontWeight: 'bold',
  transition: 'color 0.3s',
  fontSize: '14px'
};

const footerStyle = {
  padding: '10px 15px',
  backgroundColor: '#f9f9f9',
  borderBottomLeftRadius: '8px',
  borderBottomRightRadius: '8px',
  borderTop: '1px solid #ddd'
};

const postIconsStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  color: '#287094'
};

const iconStyle = {
  fontSize: '16px',
  cursor: 'pointer',
  padding: '0 5px'
};
