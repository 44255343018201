import React, { useState } from 'react';
import { Container, Form, Button } from 'react-bootstrap';
import axios from 'axios';

const AdminBlogPage = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    companyName: '',
    title: '',
    description: '',
    file: null,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    setFormData({ ...formData, file: e.target.files[0] });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const data = new FormData();
      Object.keys(formData).forEach(key => {
        data.append(key, formData[key]);
      });

      const response = await axios.post('http://hiredsafe.com/PHP/submit_blog.php', data, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });

      alert('Blog submitted successfully');
      setFormData({
        name: '',
        email: '',
        companyName: '',
        title: '',
        description: '',
        file: null,
      }); // Clear form after submission
    } catch (error) {
      console.error('There was an error submitting the blog:', error);
      alert('Error submitting blog');
    }
  };

  return (
    <main>
      <section className="blog-form-section">
        <Container>
          <h2>Create a New Admin Blog</h2>
          <Form className="blog-form" onSubmit={handleSubmit} encType="multipart/form-data">
            <Form.Group controlId="your-name">
              <Form.Label>Your Name</Form.Label>
              <Form.Control 
                type="text" 
                placeholder="Enter your name" 
                name="name" 
                value={formData.name} 
                onChange={handleChange} 
              />
            </Form.Group>
            <Form.Group controlId="your-email">
              <Form.Label>Your Email</Form.Label>
              <Form.Control 
                type="email" 
                placeholder="Enter your email" 
                name="email" 
                value={formData.email} 
                onChange={handleChange} 
              />
            </Form.Group>
            <Form.Group controlId="company-name">
              <Form.Label>Company Name (if any)</Form.Label>
              <Form.Control 
                type="text" 
                placeholder="Enter the company name" 
                name="companyName" 
                value={formData.companyName} 
                onChange={handleChange} 
              />
            </Form.Group>
            <Form.Group controlId="blog-title">
              <Form.Label>Title of The Blog</Form.Label>
              <Form.Control 
                type="text" 
                placeholder="Enter the title of the blog" 
                name="title" 
                value={formData.title} 
                onChange={handleChange} 
              />
            </Form.Group>
            <Form.Group controlId="blog-description">
              <Form.Label>Description of Blog</Form.Label>
              <Form.Control 
                as="textarea" 
                rows={5} 
                placeholder="Provide detailed description of the blog" 
                name="description" 
                value={formData.description} 
                onChange={handleChange} 
              />
            </Form.Group>
            <Form.Group controlId="upload">
              <Form.Label>Upload Image</Form.Label>
              <Form.Control 
                type="file" 
                name="file" 
                onChange={handleFileChange} 
              />
            </Form.Group>
            <Button variant="primary" type="submit">
              Submit Blog
            </Button>
          </Form>
        </Container>
      </section>
    </main>
  );
};

export default AdminBlogPage;
