import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Container, Image, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const ReportsPage = () => {
  const [publishedReports, setPublishedReports] = useState([]);
  const [showAllReports, setShowAllReports] = useState(true); // Always show all reports on this page

  const fetchReports = async () => {
    try {
      const response = await axios.get('http://hiredsafe.com/PHP/get_reports.php');
      console.log('Fetched reports:', response.data);
      setPublishedReports(response.data);
    } catch (error) {
      console.error('Error fetching reports:', error);
    }
  };

  useEffect(() => {
    fetchReports();

    const handleReportPublished = () => {
      fetchReports();
    };

    window.addEventListener('reportPublished', handleReportPublished);
    return () => window.removeEventListener('reportPublished', handleReportPublished);
  }, []);

  const displayedReports = Array.isArray(publishedReports) ? publishedReports : [];

  return (
    <div>
      <main>
        {/* Reports Section */}
        <section className="reports py-5">
          <Container>
            <h3 className="mb-4" style={{ color: '#287094' }}>All Reported Scams</h3>
            <Row className="scams">
              {displayedReports.map((report) => (
                <Col md={4} className="mb-4" key={report.id}>
                  <Card className="h-100 shadow-sm border-0" style={{ overflow: 'hidden', transition: 'transform 0.3s' }}>
                    <Card.Body className="p-0" style={{ position: 'relative' }}>
                      <Image 
                        src={report.EVIDENCE} 
                        alt="Evidence Image" 
                        fluid 
                        style={{ width: '100%', height: '200px', objectFit: 'cover' }}
                      />
                      <div style={{ padding: '20px' }}>
                        <Card.Text className="author" style={{ fontWeight: 'bold', color: '#555' }}>Reported by {report.REPORTER_NAME}</Card.Text>
                        <Card.Title style={{ color: '#287094' }}>Company Name: {report.COMPANY_NAME}</Card.Title>
                        <Card.Text style={{ color: '#333', fontSize: '14px' }}>
                          {report.DESCRIPTION_OF_SCAM && report.DESCRIPTION_OF_SCAM.length > 150 
                            ? `${report.DESCRIPTION_OF_SCAM.substring(0, 150)}...` 
                            : report.DESCRIPTION_OF_SCAM}
                        </Card.Text>
                        <Link to={`/reportdetails/${report.id}`} className="read-more" style={{ textDecoration: 'none', color: '#287094', fontWeight: 'bold' }}>Read More</Link>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
              {publishedReports.length === 0 && (
                <Col>
                  <p>No published reports available at this time.</p>
                </Col>
              )}
            </Row>
          </Container>
        </section>
      </main>
    </div>
  );
};

export default ReportsPage;
