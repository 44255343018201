import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Container, Image, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Home_Hero from "../assets/Home_Hero.jpg";

const Home = () => {
  const [publishedReports, setPublishedReports] = useState([]);
  const [blogs, setBlogs] = useState([]);
  const [showAllReports, setShowAllReports] = useState(false);
  const [showAllBlogs, setShowAllBlogs] = useState(false);

  const fetchReports = async () => {
    try {
      const response = await axios.get('http://hiredsafe.com/PHP/get_reports.php');
      console.log('Fetched reports:', response.data);
      setPublishedReports(response.data);
    } catch (error) {
      console.error('Error fetching reports:', error);
    }
  };

  const fetchBlogs = async () => {
    try {
      const response = await axios.get('http://hiredsafe.com/PHP/get_blogs.php');
      setBlogs(response.data);
    } catch (error) {
      console.error('Error fetching blogs:', error);
    }
  };

  useEffect(() => {
    fetchReports();
    fetchBlogs();

    const handleReportPublished = () => {
      fetchReports();
      fetchBlogs();
    };

    window.addEventListener('reportPublished', handleReportPublished);
    return () => window.removeEventListener('reportPublished', handleReportPublished);
  }, []);

  const displayedReports = Array.isArray(publishedReports)
    ? showAllReports 
      ? publishedReports 
      : publishedReports.slice(0, 3)
    : [];
  
  const displayedBlogs = Array.isArray(blogs)
    ? showAllBlogs 
      ? blogs 
      : blogs.slice(0, 3)
    : [];

  return (
    <div>
      <main>
        {/* Hero Section */}
        <section className="hero py-5" style={{ backgroundColor: '#f4f4f9' }}>
          <Container className="hero-container">
            <Row className="align-items-center">
              <Col md={6}>
                <div className="hero-content">
                  <h2 style={{ color: '#287094' }}>Protect Yourself From Job Scams</h2>
                  <p>Our platform helps you identify and avoid job scams, protecting your personal and financial information.</p>
                  <Button href="/reportscam" className="button" style={{ backgroundColor: '#287094', borderColor: '#287094' }}>
                    Report Scam
                  </Button>
                </div>
              </Col>
              <Col md={6} className="text-center">
                <Image
                  src={Home_Hero}
                  alt="Protect Yourself From Job Scams"
                  fluid
                  style={{ maxWidth: '100%' }}
                />
              </Col>
            </Row>
          </Container>
        </section>

        {/* Latest Scams Section */}
        <section className="latest-scams py-5">
          <Container>
            <h3 className="mb-4" style={{ color: '#287094' }}>Latest Reported Scams</h3>
            <Row className="scams">
              {displayedReports.map((report) => (
                <Col md={4} className="mb-4" key={report.id}>
                  <Card className="h-100 shadow-sm border-0" style={{ overflow: 'hidden', transition: 'transform 0.3s' }}>
                    <Card.Body className="p-0" style={{ position: 'relative' }}>
                      <Image 
                        src={report.EVIDENCE} 
                        alt="Evidence Image" 
                        fluid 
                        style={{ width: '100%', height: '200px', objectFit: 'cover' }}
                      />
                      <div style={{ padding: '20px' }}>
                        <Card.Text className="author" style={{ fontWeight: 'bold', color: '#555' }}>Reported by {report.REPORTER_NAME}</Card.Text>
                        <Card.Title style={{ color: '#287094' }}>Company Name: {report.COMPANY_NAME}</Card.Title>
                        <Card.Text style={{ color: '#333', fontSize: '14px' }}>
                          {report.DESCRIPTION_OF_SCAM && report.DESCRIPTION_OF_SCAM.length > 150 
                            ? `${report.DESCRIPTION_OF_SCAM.substring(0, 150)}...` 
                            : report.DESCRIPTION_OF_SCAM}
                        </Card.Text>
                        <Link to={`/reportdetails/${report.id}`} className="read-more" style={{ textDecoration: 'none', color: '#287094', fontWeight: 'bold' }}>Read More</Link>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
              {publishedReports.length === 0 && (
                <Col>
                  <p>No published reports available at this time.</p>
                </Col>
              )}
            </Row>
            {publishedReports.length > 3 && (
  <div className="text-center">
    <Button variant="link" as={Link} to="/reports">
      Show More
    </Button>
  </div>
)}
          </Container>
        </section>

        {/* Blog Section */}
        <section className="blog py-5" style={{ backgroundColor: '#287094' }}>
          <Container>
            <h3 className="mb-4 text-white">From the Blog</h3>
            <Row className="blog-posts">
              {displayedBlogs.map((blog) => (
                <Col md={4} className="mb-4" key={blog.id}>
                  <Card className="h-100 shadow-sm border-0" style={{ overflow: 'hidden', transition: 'transform 0.3s' }}>
                    <div className="post-image">
                      <Image 
                        src={blog.upload} 
                        alt="Blog Image" 
                        fluid 
                        style={{ width: '100%', height: '200px', objectFit: 'cover' }}
                      />
                    </div>
                    <Card.Body>
                      <Card.Text className="author" style={{ fontWeight: 'bold', color: '#555' }}>Blog By {blog.author_name}</Card.Text>
                      <Card.Text className="date" style={{ color: '#555' }}>Published on {blog.created_at ? new Date(blog.created_at).toLocaleDateString() : 'N/A'}</Card.Text>
                      <Card.Title style={{ color: '#287094', marginBottom: '10px' }}>{blog.blog_title}</Card.Title>
                      <Card.Text style={{ color: '#555', minHeight: '60px' }}>
                        {blog.blog_description && blog.blog_description.length > 150 
                          ? `${blog.blog_description.substring(0, 150)}...` 
                          : blog.blog_description || 'No description available.'}
                      </Card.Text>
                      <Link to={`/blog-details/${blog.id}`} className="read-more" style={{ textDecoration: 'none', color: '#287094', fontWeight: 'bold' }}>Read More</Link>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
              {blogs.length === 0 && (
                <Col> 
                  <p>No blogs available at this time.</p>
                </Col>
              )}
            </Row>
            {blogs.length > 3 && (
  <div className="text-center">
    <Button variant="light" as={Link} to="/blog-details">
      Show More
    </Button>
  </div>
)}

          </Container>
        </section>
      </main>
    </div>
  );
};

export default Home;
