import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ isAuthenticated, children }) => {
  if (!isAuthenticated) {
    // Redirect to login page if user is not authenticated
    return <Navigate to="/adminlogin" replace />;
  }
  // Otherwise, render the children components
  return children;
};

export default ProtectedRoute;
